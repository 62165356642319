
import { defineComponent, PropType } from 'vue'
import { useCalendar } from '@/common/composable/useCalendar'

import iEllipseOne from '@/assets/icons/EllipseOne.svg'
import iEllipseTwo from '@/assets/icons/EllipseTwo.svg'

export default defineComponent({
    components: {
        'icon-ellipse-one': iEllipseOne,
        'icon-ellipse-two': iEllipseTwo,
    },
    props: {
        range: {
            type: Object as PropType<{ date_from: Date; date_to: Date }>,
            default: null,
        },
    },
    setup() {
        const { getMonthName } = useCalendar()
        return { getMonthName }
    },
})

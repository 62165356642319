
import { defineComponent, onMounted, reactive, toRaw } from 'vue'

import SCalendar from '@/common/components/SCalendar/index.vue'
import SelectedDateRange from '@/modules/HistoryNew/components/SelectedDateRange/index.vue'
import SInput from '@/common/components/SInput/index.vue'
import SButton from '@/common/components/SButton/index.vue'

import iClose from '@/assets/icons/Close.svg'

import { LIFTING_CAPACITY_OPTIONS, LOADING_TYPE_OPTIONS } from '@/core/constants/FTL.constants'
import { PAYMENT_TYPE_OPTIONS } from '@/core/constants/common.constants'

import { filtersCitiesState, useHistory } from '@/modules/HistoryNew/composable/useHistory'
import { FTLHistoryFilters } from '@/core/classes/FTLHistoryFilters.class'

export default defineComponent({
    components: {
        's-calendar': SCalendar,
        'selected-date-range': SelectedDateRange,
        // 's-input': SInput,
        's-button': SButton,
        'icon-close': iClose,
    },
    emits: ['close'],
    setup(_, { emit }) {
        const { resetFilters, setFTLFilters, FTLFiltersState, loadData, loadCities } = useHistory()

        const state = reactive(new FTLHistoryFilters())

        onMounted(() => {
            loadCities()

            state.sender_city = FTLFiltersState.sender_city
            state.date_range = FTLFiltersState.date_range
            state.status = FTLFiltersState.status
            state.points_count = FTLFiltersState.points_count
            state.lifting_capacity = FTLFiltersState.lifting_capacity
            state.cubic_capacity = FTLFiltersState.cubic_capacity
            state.loading_type = FTLFiltersState.loading_type
            state.temperature_regim = FTLFiltersState.temperature_regim
            state.payment_type = FTLFiltersState.payment_type
        })

        const emitClose = () => {
            emit('close')
        }

        const resetFiltersHandler = () => {
            state.date_range = {
                date_from: null,
                date_to: null,
            }
            state.sender_city = FTLFiltersState.sender_city
            state.points_count = null
            state.status = null
            state.lifting_capacity = null
            state.cubic_capacity = null
            state.loading_type = null
            state.temperature_regim = null
            state.payment_type = null

            resetFilters()
            emitClose()
            loadData()
        }

        const applyFilters = () => {
            emitClose()
            setFTLFilters(toRaw(state))
            loadData()
        }

        return {
            LIFTING_CAPACITY_OPTIONS,
            LOADING_TYPE_OPTIONS,
            PAYMENT_TYPE_OPTIONS,
            state,
            emitClose,
            resetFiltersHandler,
            applyFilters,
            filtersCitiesState,
        }
    },
})

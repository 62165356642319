import { DAY_NAMES, MONTH_NAMES } from '@/core/constants/Calendar.constants'

export const useCalendar = () => {
    const getDayName = (day_number: number) => DAY_NAMES[day_number]

    const getMonthName = (month_number: number) => {
        const monthName = MONTH_NAMES[month_number]
        const monthNameChars = monthName.split('')

        const lastChar = monthNameChars[monthNameChars.length - 1]

        if (lastChar === 'т') {
            monthNameChars.push('а')
        } else {
            monthNameChars[monthNameChars.length - 1] = 'я'
        }

        const monthNameFormatted = monthNameChars.join('')
        return monthNameFormatted
    }

    return { getDayName, getMonthName }
}
